import axios from "axios"
import { API_URL } from "./config"

const request = axios.create({
  baseURL: API_URL,
  timeout: 5000
})
// 请求拦截器
request.interceptors.request.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截器
request.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default request
