<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-top-first">
        <img
          class="logo"
          @click="homeClick"
          v-lazy="IMG_URL + 'pc/images/logo-white.png'"
          alt=""
        />
      </div>
      <div class="footer-top-second">
        <span class="title">软件开发</span>
        <ul>
          <li
            v-for="item in appList"
            :key="item.id"
            @click="handleClick(item.id)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="footer-top-third">
        <span class="title">行业解决方案</span>
        <ul>
          <li v-for="item in list" :key="item.id" @click="handleClick(item.id)">
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="footer-top-forth">
        <!-- <span class="title" @click="handleClick(11)">客户案例</span> -->
        <ul>
          <li @click="handleClick(11)">客户案例</li>
          <li @click="handleClick(12)">新闻中心</li>
          <li @click="handleClick(13)">关于我们</li>
        </ul>
      </div>
      <div class="footer-top-fifth">
        <span class="title">联系我们</span>
        <ul>
          <li v-for="(item, index) in contractList" :key="index">
            <img v-lazy="IMG_URL + item.icon" alt="" />
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="footer-top-sixth">
        <span class="title">关注公众号</span>
        <!-- <div class="QRcode"></div> -->
        <img v-lazy="IMG_URL + 'pc/images/code.jpg'" alt="" class="QRcode" />
      </div>
    </div>
    <div class="footer-bottom">
      Copyright © 2024 合肥和行科技有限公司 All Rights Reserved.
      <img class="img" v-lazy="IMG_URL + 'pc/icons/icon12.png'" alt="" />
      皖公网安备 34019102000434号 皖ICP备18015830号-7
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      appList: [
        { id: 0, label: "APP开发" },
        { id: 1, label: "小程序开发" },
        { id: 2, label: "大型系统定制开发" },
      ],
      list: [
        { id: 3, label: "网约车平台" },
        { id: 4, label: "出租车平台" },
        { id: 5, label: "车辆管理系统" },
        { id: 6, label: "车辆租赁系统" },
        { id: 7, label: "网络货运平台" },
        { id: 8, label: "车队管理平台" },
        { id: 9, label: "培训考试系统" },
        { id: 10, label: "电池监管平台" },
      ],
      contractList: [
        {
          icon: "pc/icons/icon11.png",
          label: "service@jachx.com",
        },
        {
          icon: "pc/icons/icon9.png",
          label: "159-5513-7957",
        },
        {
          icon: "pc/icons/icon10.png",
          label: "安徽省合肥市蜀山区紫蓬路1325号",
        },
      ],
    };
  },
  methods: {
    homeClick() {
      this.$router.push("/");
    },
    handleClick(id) {
      let url = "https://www.jachx.com/#";
      switch (id) {
        case 0:
          return window.open(url + "/software?type=0");
        case 1:
          return window.open(url + "/software?type=1");
        case 2:
          return window.open(url + "/software?type=2");
        case 3:
          return window.open(url + "/solution?type=3");
        case 4:
          return window.open(url + "/solution?type=4");
        case 5:
          return window.open(url + "/solution?type=5");
        case 6:
          return window.open(url + "/solution?type=6");
        case 7:
          return window.open(url + "/solution?type=7");
        case 8:
          return window.open(url + "/solution?type=8");
        case 9:
          return window.open(url + "/solution?type=9");
        case 10:
          return window.open(url + "/solution?type=10");
        case 11:
          return window.open(url + "/case");
        case 12:
          return window.open(url + "/news");
        case 13:
          return window.open(url + "/about");
        default:
          return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background-color: #313a54;

  &-top {
    display: flex;
    justify-content: space-between;
    width: 90.32rem;
    max-width: 1445.12px;
    margin: 0 auto;
    text-align: left;
    padding: 4.75rem 0 4.19rem;

    &-first,
    &-second,
    &-third,
    &-forth,
    &-fifth,
    &-sixth {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 1.75rem;
      }

      li {
        color: #a5a9b4;
        font-size: 1rem;
        line-height: 1.38rem;
        font-weight: 400;
        white-space: nowrap;
      }
    }

    &-first {
      .logo {
        width: 8.13rem;
        height: 1.56rem;
        cursor: pointer;
      }
    }

    &-second,
    &-third,
    &-forth,
    &-fifth {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        img {
          width: 1.38rem;
          height: 1.38rem;
          margin-right: 0.44rem;
        }
      }
    }

    &-second,
    &-third,
    &-forth {
      li {
        cursor: pointer;
      }

      li:hover {
        color: #fff;
      }
    }

    &-forth {
      li {
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 500;
        margin-bottom: 1.75rem;
      }
    }

    &-sixth {
      .QRcode {
        width: 12.69rem;
        height: 12.69rem;
        background: #ffffff;
      }
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.69rem;
    background-color: #202a45;
    color: #8a8b90;
    font-size: 0.94rem;
    line-height: 1.31rem;

    .img {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 1rem;
    }
  }
}
</style>
