<template>
  <div class="floats">
    <!-- <div class="customer" @click="handleClick">
      <img v-lazy="IMG_URL + 'pc/images/customer.png'" alt="" />
      <div class="label">客服</div>
    </div> -->
    <div class="code">
      <img class="icon" v-lazy="IMG_URL + 'pc/icons/icon13.png'" alt="" />
      <div class="label">二维码</div>
      <div class="img"></div>
      <img :src="IMG_URL + 'pc/images/code.jpg'" class="img" alt="" />
    </div>
    <img
      v-if="toTop"
      class="top"
      @click="backTop"
      v-lazy="IMG_URL + 'pc/images/top.png'"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "Floats",
  data() {
    return {
      // 滚动条参数
      scrollTop: 0,
      // 控制是否展示回到顶部
      toTop: false,
    }
  },
  watch: {
    scrollTop(newValue, oldValue) {
      if (newValue >= 400) {
        this.toTop = true;
      } else {
        this.toTop = false;
      }
    },
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    // 浏览器滚动
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    },
    // 回到顶部
    backTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    // 客服
    // handleClick() {
    //   const element = document.querySelector(".embed-hot-issue-item");
    //   if (element) {
    //     element.click();
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.floats {
  .code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 105px;
    background: #2f80f4;
    cursor: pointer;
    border-radius: 5px;
    // position: relative;
    position: fixed;
    right: 2px;
    top: calc(50% + 115px);
    transform: translateY(-50%);
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    z-index: 2;

    .icon {
      width: 36px;
      height: 35px;
      margin-bottom: 13px;
    }

    .label {
      font-size: 16px;
      color: #ffffff;
      line-height: 28px;
    }

    .img {
      width: 160px;
      height: 0;
      opacity: 0;
      top: 0;
      right: 67px;
      background: grey;
      position: absolute;
      transition: all 0.3s ease;
    }
  }

  // .customer {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   border-radius: 0.88rem 0.88rem 0 0;
  //   padding-top: 1.31rem;

  //   .label {
  //     margin-bottom: 0.69rem;
  //   }
  // }

  // .customer::after {
  //   content: "";
  //   display: block;
  //   width: 3.75rem;
  //   height: 0.06rem;
  //   background: #ffffff54;
  // }

  // .code {
  //   .img {
  //     width: 12rem;
  //     height: 0;
  //     opacity: 0;
  //     top: 0;
  //     left: -12.5rem;
  //     background: grey;
  //     position: absolute;
  //     transition: all 0.3s ease;
  //   }
  // }

  .code:hover {
    .img {
      height: 160px;
      opacity: 1;
    }
  }

  .top {
    width: 32px;
    height: 40px;
    margin-top: 17px;
    cursor: pointer;
    position: fixed;
    right: 11.5px;
    top: calc(50% + 200px);
    transform: translateY(-50%);
    z-index: 2;
  }
}
</style>