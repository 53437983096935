<template>
  <div class="header-wrapper" @mouseleave="showSubTabId = null">
    <div class="header">
      <div class="header-left">
        <img
          class="logo"
          @click="handleClick(0)"
          v-lazy="IMG_URL + 'pc/images/logo1.png'"
          alt=""
        />
        <ul>
          <li
            @click="handleClick(item.id)"
            :class="navbarIdx === item.id ? 'active' : ''"
            v-for="item in navbar"
            :key="item.id"
            @mouseenter="handleMouseenter(item.id)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="header-right">
        <div class="phone">
          <img class="icon" v-lazy="IMG_URL + 'pc/icons/mobile.png'" alt="" />
          <span>159-5513-7957</span>
        </div>
        <div class="button" @click="$refs.pop.open()">预约演示</div>
      </div>
      <!-- 子tab -->
      <div
        class="sub-tab-wrapper"
        :style="{
          height:
            showSubTabId === 1
              ? '25.03rem'
              : showSubTabId === 2
              ? '30.19rem'
              : '0',
          paddingTop: showSubTabId ? '4.63rem' : '0',
          opacity: showSubTabId ? 1 : 0,
        }"
      >
        <div
          class="sub-tab"
          :style="{ paddingLeft: showSubTabId === 1 ? '2rem' : '13.68rem' }"
        >
          <div class="sub-tab-template">
            <div
              class="item"
              @click.stop="subTabClick(v.id)"
              v-for="v in subTabList"
              :key="v.id"
              :style="{
                width: showSubTabId === 1 ? '100%' : '50%',
              }"
            >
              <img v-lazy="IMG_URL + v.icon" alt="" />
              {{ v.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pop ref="pop"></Pop>
  </div>
</template>

<script>
import Pop from "./Pop.vue";

export default {
  name: "Header",
  components: { Pop },
  data() {
    return {
      navbar: [
        { id: 0, label: "首页" },
        { id: 1, label: "软件开发" },
        { id: 2, label: "行业解决方案" },
        { id: 3, label: "客户案例" },
        { id: 4, label: "新闻中心" },
        { id: 5, label: "关于我们" },
      ],
      navbarIdx: 0,
      appTab: [
        { id: 0, label: "APP开发", icon: "pc/images/solute/icon137.png" },
        { id: 1, label: "小程序开发", icon: "pc/images/solute/icon138.png" },
        {
          id: 2,
          label: "大型系统定制开发",
          icon: "pc/images/solute/icon139.png",
        },
      ],
      solutionTab: [
        {
          id: 3,
          label: "网约车平台",
          icon: "pc/images/solute/icon140.png",
        },
        {
          id: 7,
          label: "网络货运平台",
          icon: "pc/images/solute/icon130.png",
        },
        {
          id: 4,
          label: "出租车平台",
          icon: "pc/images/solute/icon131.png",
        },
        {
          id: 8,
          label: "车队管理平台",
          icon: "pc/images/solute/icon134.png",
        },
        {
          id: 5,
          label: "车辆管理系统",
          icon: "pc/images/solute/icon132.png",
        },
        {
          id: 9,
          label: "培训考试系统",
          icon: "pc/images/solute/icon135.png",
        },
        {
          id: 6,
          label: "车辆租赁系统",
          icon: "pc/images/solute/icon133.png",
        },
        {
          id: 10,
          label: "电池监管平台",
          icon: "pc/images/solute/icon136.png",
        },
      ],
      subTabList: [],
      subTabIndex: null,
      showSubTabId: null,
    };
  },
  watch: {
    // 监听路由变化
    $route(to) {
      if (to.name === "Index") {
        this.navbarIdx = 0;
      } else if (to.name === "Software") {
        this.navbarIdx = 1;
      } else if (to.name === "Solution") {
        this.navbarIdx = 2;
      } else if (to.name === "Case" || to.name === "CaseDetail") {
        this.navbarIdx = 3;
      } else if (to.name === "News" || to.name === "NewsDetail") {
        this.navbarIdx = 4;
      } else if (to.name === "About") {
        this.navbarIdx = 5;
      }
    },
  },
  methods: {
    // 导航栏切换
    handleClick(id) {
      this.navbarIdx = id;
      this.subTabIndex = null;
      if (id === 1 && this.$route.name !== "Software")
        return this.$router.push("/software?type=0");
      if (id === 2 && this.$route.name !== "Solution")
        return this.$router.push("/solution?type=3");
      switch (id) {
        case 0:
          return this.$router.push("/");
        case 3:
          return this.$router.push("/case");
        case 4:
          return this.$router.push("/news");
        case 5:
          return this.$router.push("/about");
        default:
          return;
      }
    },
    handleMouseenter(id) {
      if (id === 1 || id === 2) {
        this.showSubTabId = id;
        this.subTabList = id === 1 ? this.appTab : this.solutionTab;
      } else {
        this.showSubTabId = null;
      }
    },
    // 子tab切换
    subTabClick(id) {
      this.subTabIndex = id;
      this.showSubTabId = null;
      if (id === 0 || id === 1 || id === 2) {
        this.navbarIdx = 1;
      } else {
        this.navbarIdx = 2;
      }
      switch (id) {
        case 0:
          return this.$router.push("/software?type=0");
        case 1:
          return this.$router.push("/software?type=1");
        case 2:
          return this.$router.push("/software?type=2");
        case 3:
          return this.$router.push("/solution?type=3");
        case 4:
          return this.$router.push("/solution?type=4");
        case 5:
          return this.$router.push("/solution?type=5");
        case 6:
          return this.$router.push("/solution?type=6");
        case 7:
          return this.$router.push("/solution?type=7");
        case 8:
          return this.$router.push("/solution?type=8");
        case 9:
          return this.$router.push("/solution?type=9");
        case 10:
          return this.$router.push("/solution?type=10");
        default:
          return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.69rem;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 0.13rem 0.25rem 0 #0000000d;
  z-index: 3;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 105.88rem;
    height: 100%;
    margin: 0 auto;

    &-left {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 100%;

      .logo {
        width: 8.13rem;
        height: 1.56rem;
        margin-right: 2.62rem;
        cursor: pointer;
      }

      ul {
        display: flex;
        height: 100%;

        li {
          height: 100%;
          font-size: 1.25rem;
          color: #2a303f;
          margin-left: 1.88rem;
          line-height: 4.69rem;
          cursor: pointer;
          // transition: all 0.3s ease;
          white-space: nowrap;
          position: relative;

          &::after {
            content: "";
            width: 0;
            display: block;
            height: 0.26rem;
            background-color: #428de5;
            transition: all 0.3s ease;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        li:hover {
          color: #428de5;
        }

        .active {
          color: #428de5;
          font-weight: 600;

          &::after {
            width: 70%;
          }
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .phone {
        display: flex;
        align-items: center;
        animation: number 5s ease-in-out infinite reverse;

        .icon {
          width: 1.13rem;
          height: 1.13rem;
        }

        span {
          font-size: 1rem;
          color: #575d6c;
          margin: 0 2.31rem 0 0.75rem;
        }
      }

      .button {
        width: 7.19rem;
        height: 2.88rem;
        background: #2283e9;
        border: 0.06rem solid #dae0ea;
        border-radius: 0.38rem;
        font-weight: 500;
        font-size: 1rem;
        color: #ffffff;
        text-align: center;
        line-height: 2.88rem;
        cursor: pointer;
      }

      .button:hover {
        background: #2278d4;
      }
    }

    .sub-tab-wrapper {
      width: 100%;
      position: absolute;
      top: 4.69rem;
      left: 0;
      background: #ffffff;
      box-sizing: border-box;
      box-shadow: 0 1.88rem 1.88rem 0.25rem #00000014;
      transition: all 0.3s ease;
      overflow: hidden;

      .sub-tab {
        width: 75rem;
        max-width: 1200px;
        margin: 0 auto;
        transition: all 0.3s ease;

        &-template {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 33.19rem;

          .item {
            display: flex;
            align-items: center;
            font-size: 1.13rem;
            color: #252b3a;
            margin-bottom: 2.06rem;
            cursor: pointer;
            transition: all 0.2s linear;

            img {
              width: 2.63rem;
              height: 2.63rem;
              margin-right: 0.44rem;
            }
          }

          .item:hover {
            color: #2283e9;
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

@keyframes number {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }
}
</style>