import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'
import './assets/common.scss'
import './assets/font/font.scss'
import { IMG_URL } from './utils/config'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { parseTime, handleIntersect } from './utils/utils'
import VueLazyLoad from 'vue-lazyload'
import 'animate.css';
import "wowjs/css/libs/animate.css"// 巨坑... 此地方要引入两个css
import wow from 'wowjs'
// 引入插件lenis实现惯性滚动
import Lenis from 'lenis'

// 初始化lenis
const lenis = new Lenis()
function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}
requestAnimationFrame(raf)

Vue.config.productionTip = false

Vue.prototype.$wow = wow
Vue.prototype.IMG_URL = IMG_URL
Vue.prototype.parseTime = parseTime
Vue.prototype.handleIntersect = handleIntersect
Vue.prototype.$lenis = lenis;

Vue.use(VueAwesomeSwiper)
Vue.use(VueLazyLoad)

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}
Vue.directive('intersect', {
  bind(el, binding, vnode) {
    if (typeof IntersectionObserver === 'undefined') {
      console.warn('IntersectionObserver is not supported in this browser.');
      return;
    }

    const debouncedCallback = debounce((isIntersecting, target) => {
      binding.value(isIntersecting, target);
    }, 100); // 延迟100毫秒

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 如果元素进入可视区域，执行渐入效果
            debouncedCallback(true, entry.target);
          } else {
            // 如果元素离开可视区域，执行渐出效果
            debouncedCallback(false, entry.target);
          }
        });
      },
      {
        threshold: 0.1, // 当相交面积大于等于10%时触发回调
      }
    );
    el.__observer__ = observer; // 存储观察者实例
    observer.observe(el);
  },
  unbind(el) {
    if (el.__observer__) {
      el.__observer__.disconnect(); // 销毁观察者
      delete el.__observer__; // 删除存储的观察者实例
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
