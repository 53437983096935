var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrapper",on:{"mouseleave":function($event){_vm.showSubTabId = null}}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-left"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.IMG_URL + 'pc/images/logo1.png'),expression:"IMG_URL + 'pc/images/logo1.png'"}],staticClass:"logo",attrs:{"alt":""},on:{"click":function($event){return _vm.handleClick(0)}}}),_c('ul',_vm._l((_vm.navbar),function(item){return _c('li',{key:item.id,class:_vm.navbarIdx === item.id ? 'active' : '',on:{"click":function($event){return _vm.handleClick(item.id)},"mouseenter":function($event){return _vm.handleMouseenter(item.id)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)]),_c('div',{staticClass:"header-right"},[_c('div',{staticClass:"phone"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.IMG_URL + 'pc/icons/mobile.png'),expression:"IMG_URL + 'pc/icons/mobile.png'"}],staticClass:"icon",attrs:{"alt":""}}),_c('span',[_vm._v("159-5513-7957")])]),_c('div',{staticClass:"button",on:{"click":function($event){return _vm.$refs.pop.open()}}},[_vm._v("预约演示")])]),_c('div',{staticClass:"sub-tab-wrapper",style:({
        height:
          _vm.showSubTabId === 1
            ? '25.03rem'
            : _vm.showSubTabId === 2
            ? '30.19rem'
            : '0',
        paddingTop: _vm.showSubTabId ? '4.63rem' : '0',
        opacity: _vm.showSubTabId ? 1 : 0,
      })},[_c('div',{staticClass:"sub-tab",style:({ paddingLeft: _vm.showSubTabId === 1 ? '2rem' : '13.68rem' })},[_c('div',{staticClass:"sub-tab-template"},_vm._l((_vm.subTabList),function(v){return _c('div',{key:v.id,staticClass:"item",style:({
              width: _vm.showSubTabId === 1 ? '100%' : '50%',
            }),on:{"click":function($event){$event.stopPropagation();return _vm.subTabClick(v.id)}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.IMG_URL + v.icon),expression:"IMG_URL + v.icon"}],attrs:{"alt":""}}),_vm._v(" "+_vm._s(v.label)+" ")])}),0)])])]),_c('Pop',{ref:"pop"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }