<template>
  <div class="pop animate__animated animate__fadeInDown" v-if="visible">
    <div class="pop-content">
      <img
        @click="close"
        class="close"
        v-lazy="IMG_URL + 'pc/images/close.png'"
        alt=""
      />
      <div class="content">
        <img
          class="content-left"
          v-lazy="IMG_URL + 'pc/images/bg1.png'"
          alt=""
        />
        <div class="content-right">
          <span>预约演示</span>
          <div class="desc">
            请留下您的信息，我们的商务经理将在24小时内联系您
          </div>
          <div class="form">
            <div class="form-item">
              <img
                class="icon"
                v-lazy="IMG_URL + 'pc/images/icon3.png'"
                alt=""
              />
              <input
                v-model="form.name"
                type="text"
                maxlength="30"
                placeholder="请输入姓名"
                @input="nameInput"
              />
              <div class="error" v-if="NameErrorMsg">
                {{ NameErrorMsg }}
              </div>
            </div>
            <div class="form-item">
              <img
                class="icon"
                v-lazy="IMG_URL + 'pc/images/icon1.png'"
                alt=""
              />
              <input
                v-model="form.mobile"
                type="text"
                maxlength="11"
                placeholder="请输入手机号"
                @input="mobileInput"
              />
              <div class="error" v-if="MobileErrorMsg">
                {{ MobileErrorMsg }}
              </div>
            </div>
            <div class="form-item">
              <img
                class="icon"
                v-lazy="IMG_URL + 'pc/images/icon2.png'"
                alt=""
              />
              <input
                v-model="form.company"
                type="text"
                maxlength="50"
                placeholder="请输入公司名称"
              />
              <div class="error" v-if="CompanyErrorMsg">
                {{ CompanyErrorMsg }}
              </div>
            </div>
          </div>
          <button
            :class="btnDisabled ? 'disable-btn btn' : 'btn'"
            @click="submit"
            :disabled="btnDisabled"
          >
            {{ btnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reservationApi } from "@/utils/api";

export default {
  name: "Pop",
  data() {
    return {
      visible: false,
      form: {},
      NameErrorMsg: "",
      MobileErrorMsg: "",
      CompanyErrorMsg: "",
      btnText: "提交",
      btnDisabled: false,
    };
  },
  methods: {
    // 打开弹窗
    open() {
      this.visible = true;
      // 禁止页面滚动
      this.$lenis.stop();
    },
    // 关闭弹窗并重置所有数据
    close() {
      this.visible = false;
      this.form = {};
      this.NameErrorMsg = "";
      this.MobileErrorMsg = "";
      this.CompanyErrorMsg = "";
      this.btnText = "提交";
      this.btnDisabled = false;
      // 允许页面滚动
      this.$lenis.start();
    },
    // 公司名称校验
    nameInput() {
      if (this.form.name) {
        this.NameErrorMsg = "";
        return true;
      } else {
        this.NameErrorMsg = "请输入您的姓名";
        return false;
      }
    },
    // 手机号校验
    mobileInput() {
      const reg = /^1[3-9]\d{9}$/;
      if (this.form.mobile) {
        if (reg.test(this.form.mobile)) {
          this.MobileErrorMsg = "";
          return true;
        } else {
          this.MobileErrorMsg = "请输入正确的手机号";
          return false;
        }
      } else {
        this.MobileErrorMsg = "请输入您的手机号";
        return false;
      }
    },
    // 公司名称校验
    // companyInput() {
    //   if (this.form.company) {
    //     this.CompanyErrorMsg = "";
    //     return true;
    //   } else {
    //     this.CompanyErrorMsg = "请输入您的公司名称";
    //     return false;
    //   }
    // },
    // 提交
    submit() {
      this.nameInput();
      this.mobileInput();
      // this.companyInput();
      if (this.nameInput() && this.mobileInput()) {
        this.btnText = "提交中...";
        this.btnDisabled = true;
        reservationApi(this.form)
          .then((res) => {
            if (res.returnCode === 10000) {
              this.btnText = "提交成功";
              setTimeout(() => {
                this.close();
              }, 500);
            }
          })
          .catch(() => {
            this.btnText = "提交";
            this.btnDisabled = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pop {
  width: 100%;
  height: 100%;
  background: #00000096;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;

  &-content {
    display: flex;
    flex-direction: column;
    width: 83.31rem;
    max-width: 1332.96px;
    height: 49.75rem;
    position: absolute;
    top: calc((100% - 49.75rem) / 2);
    left: calc((100% - 83.31rem) / 2);
    border-radius: 1.13rem;
    background: #ffffff;
    padding: 2.88rem 4.19rem 0;
    box-sizing: border-box;

    .close {
      width: 1.38rem;
      height: 1.38rem;
      align-self: flex-end;
      cursor: pointer;
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-self: center;
      width: 100%;
      margin-top: 4.81rem;

      &-left {
        width: 31.31rem;
        height: 24.5rem;
        margin-top: 4.19rem;
      }

      &-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 37.47rem;

        span {
          font-weight: 600;
          font-size: 2.38rem;
          color: #252b3a;
        }

        .desc {
          font-size: 1rem;
          color: #868686;
          margin: 0.88rem 0 2.63rem;
        }

        .form {
          width: 100%;

          &-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 4rem;
            border: 0.06rem solid #dfdfdf;
            border-radius: 0.25rem 0 0.25rem 0.25rem 0.25rem;
            margin-bottom: 1.88rem;
            padding: 0 1.62rem;
            box-sizing: border-box;
            position: relative;

            .icon {
              width: 1.5rem;
              height: 1.65rem;
              margin-right: 1.74rem;
            }

            input {
              width: calc(100% - 1.74rem - 1.5rem);
              height: 1.94rem;
              border: none;
              border-left: solid 0.13rem #d8d8d8;
              padding: 0 1.74rem;
              box-sizing: border-box;
              color: #252b3a;
              font-size: 1.25rem;
            }

            input::placeholder {
              font-size: 1.25rem;
              color: #a6a6a6;
            }

            input:focus {
              outline: none;
            }

            .error {
              position: absolute;
              bottom: -1.6rem;
              left: 0;
              font-size: 1rem;
              color: #f56c6c;
            }
          }

          &-item:first-child {
            .icon {
              width: 1.5rem;
              height: 1.63rem;
            }
          }

          &-item:last-child {
            margin-bottom: 3.88rem;

            .icon {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }

        .btn {
          width: 100%;
          height: 3.5rem;
          background: #2283e9;
          border-radius: 0.38rem;
          font-size: 1.25rem;
          color: #ffffff;
          line-height: 3.5rem;
          cursor: pointer;
        }

        .disable-btn {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>