<template>
  <div id="app">
    <!-- <div class="pc" v-if="!isMobile()"> -->
    <div class="pc">
      <!-- 导航栏 -->
      <Header></Header>
      <!-- 悬浮组件 -->
      <Floats></Floats>
      <!-- 主体部分 -->
      <router-view />
      <!-- 底部 -->
      <Footer></Footer>
    </div>
    <!-- <div class="mb" v-else></div> -->
  </div>
</template>

<script>
import Header from "@/views/pc/components/Header.vue";
import Footer from "@/views/pc/components/Footer.vue";
import Floats from "@/views/pc/components/Floats.vue";

export default {
  name: "LayOut",
  components: { Header, Footer, Floats },
  methods: {
    // 判断是否是手机端，如果是，返回true
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Alibaba_PuHuiTi_2.0_55_Regular_55_Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
