import request from "./request"

// 预约演示接口
export function reservationApi(query) {
  return request({
    url: 'show/apply/user',
    method: 'GET',
    params: query
  })
}

// 新闻分页接口
export function newsPageApi(pageNo) {
  return request({
    url: 'news/page?nowPage=' + pageNo,
    method: 'GET',
  })
}

// 新闻不分页接口
export function newsApi() {
  return request({
    url: 'news/list',
    method: 'GET',
  })
}

// 新闻详情接口
export function newsDetailApi(uuid) {
  return request({
    url: 'news/detail?uuid=' + uuid,
    method: 'GET',
  })
}
