import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决跳转重复路由报错问题
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
}
Vue.use(VueRouter)

const routes = [
  /* -------------------------------------------------------PC */
  // 首页
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/pc/Index.vue')
  },
  // 软件开发
  {
    path: '/software',
    name: 'Software',
    component: () => import('../views/pc/Software.vue')
  },
  // 行业解决方案
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('../views/pc/Solution.vue')
  },
  // 客户案例
  {
    path: '/case',
    name: 'Case',
    component: () => import('../views/pc/Case.vue')
  },
  // 案例详情
  {
    path: '/case/detail/:id',
    name: 'CaseDetail',
    component: () => import('../views/pc/CaseDetail.vue')
  },
  // 新闻中心
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/pc/News.vue')
  },
  // 新闻详情
  {
    path: '/news/detail/:uuid',
    name: 'NewsDetail',
    component: () => import('../views/pc/NewsDetail.vue')
  },
  // 关于我们
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/pc/About.vue')
  }
  /* -------------------------------------------------------PC */
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 切换页面时回到顶部
router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  })
  next()
})

export default router
